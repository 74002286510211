import { Link } from "gatsby"
import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <main className="not__found">
      <h1 className="error">404</h1>
      <h2 className="message">Ups! Page not found</h2>
      <Link to="/" className="btn btn--error-404">Go back to the homepage</Link>
    </main>
  </>
)

export default NotFoundPage
